<template>
  <div class="admin-view-partner">
    <loading-screen :is-loading="isRevoking"></loading-screen>

    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">Review Partner Integration</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type="primary"
          @click.prevent="goBack"
          >Back</a-button
        >

        <a-popconfirm
          title="Are you sure?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="attemptRevoke"
        >
          <a-button
            class="button-margin-left btn-rounded"
            icon="delete"
            size="large"
            type="danger"
            >Revoke</a-button
          >
        </a-popconfirm>
      </div>
    </div>
    <!-- / Page Header -->

  <organisation-details-panel></organisation-details-panel>

    <!-- Card - Basic Details -->
    <div class="card basic-details flex">
      <div class="left">
        <api-client-thumbnail
          :tenant-id="tenantId"
          size="large"
          :api-client="apiClient"
        ></api-client-thumbnail>
      </div>
      <div class="right">
        <h3>{{ apiClient.displayName }}</h3>

        {{ apiClient.headline }}
      </div>
    </div>
    <!-- / Card - Basic Details -->

    <!-- Loading -->
    <div v-if="isLoading" class="card loading">
      <a-spin></a-spin>
    </div>
    <!-- / Loading -->

    <!-- API Grant Exists -->
    <div v-if="!isLoading && apiGrant" class="api-grant-details">
      <a-row :gutter="20" type="flex">
        <!-- Granted Permissions -->
        <a-col :span="colSpan">
          <div class="col-inner">
            <div class="card granted-permissions">
              <h3>Granted Permissions</h3>

              <a-alert
                v-if="grantedPermissions.length == 0"
                type="error"
                message="No permissions have been granted"
              />

              <div v-if="grantedPermissions.length">
                <ul>
                  <li v-for="(permission, i) in grantedPermissions" :key="i">
                    {{ permission.description }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </a-col>
        <!-- / Granted Permissions -->

        <!-- Grant Information -->
        <a-col :span="colSpan">
          <div class="col-inner">
            <div class="card grant-information">
              <h3>Grant Information</h3>

              <div>
                <p>
                  Granted
                  {{
                    apiGrant.grantedAt
                      ? displayTimeAgo(apiGrant.grantedAt)
                      : "-"
                  }}
                  <span v-if="apiGrant.grantedAt"
                    >({{ formatGrantedAt(apiGrant.grantedAt) }})</span
                  >
                </p>
              </div>

              <div v-if="apiGrant.grantedBy">
                Granted by {{ getGrantedByName(apiGrant.grantedBy) }}
              </div>
            </div>
          </div>
        </a-col>
        <!-- / Grant Information  -->
      </a-row>

      <a-row :gutter="20" type="flex">
        <!-- Authentication -->
        <a-col :span="colSpan" v-if="apiClient && apiClient.linkTargetId">
          <div class="col-inner">
            <div class="card authentication">
              <h3>Authentication</h3>

              <!-- Loader -->
              <div v-if="isLoadingLinkTarget || isLoadingApiGrantSecret">
                <a-spin></a-spin>
              </div>
              <!-- / Loader -->

              <!-- Loaded -->
              <div v-if="!(isLoadingLinkTarget || isLoadingApiGrantSecret)">
                <!-- Nothing to show -->
                <div
                  v-if="
                    !linkTarget.organisationKeyName && !linkTarget.secretName
                  "
                >
                  Nothing to show here
                </div>
                <!-- / Nothing to show -->

                <!-- Details -->
                <div v-if="!isLoading">
                  <a-row :gutter="15">
                    <a-col :span="12" v-if="linkTarget.organisationKeyName">
                      <b>{{ linkTarget.organisationKeyName }}:</b>
                      {{
                        apiGrantSecret && apiGrantSecret.organisationKey
                          ? apiGrantSecret.organisationKey
                          : "Unknown"
                      }}
                    </a-col>
                    <a-col :span="12" v-if="linkTarget.secretName">
                      <b>{{ linkTarget.secretName }}:</b>
                      {{
                        apiGrantSecret && apiGrantSecret.secretPresent
                          ? "*****"
                          : "Not set"
                      }}
                    </a-col>
                  </a-row>

                  <!-- Edit btn -->
                  <div v-if="!isLoading && linkTarget && canEditApiClient" class="edit-link-target">
                    <a-button @click.prevent="editLinkTarget" type="primary"
                      >Edit</a-button
                    >
                  </div>
                  <!-- / Edit btn -->
                </div>
                <!-- Details -->
              </div>
              <!-- / Loaded -->
            </div>
          </div>
        </a-col>
        <!-- / Authentication -->

        <!-- Support information -->
        <a-col :span="colSpan">
          <div class="col-inner">
            <div class="card support-information">
              <h3>Support Information</h3>

              <div class="support-information-buttons">
                <a-button
                  v-if="apiClient.privacyPolicyUrl"
                  @click.prevent="openLink(apiClient.privacyPolicyUrl)"
                  type="default"
                  icon="link"
                  >Privacy Policy</a-button
                >
                <a-button
                  v-if="apiClient.supportUrl"
                  @click.prevent="openLink(apiClient.supportUrl)"
                  type="default"
                  icon="link"
                  >Support Website</a-button
                >
                <a-button
                  @click.prevent="openLink('mailto:' + apiClient.supportEmail)"
                  type="default"
                  icon="link"
                  >Support Email</a-button
                >
              </div>
            </div>
          </div>
        </a-col>
        <!-- / Support information -->

        <!-- Email Integration -->
        <a-col :span="colSpan" v-if="apiGrant && apiGrant.parser">
          <div class="col-inner">
            <div class="card email-integration">
              <h3>Email Integration</h3>
              <div>
                <p v-if="apiGrant.shortCode">
                  <span class="mr-2">Email:</span>
                  {{ apiGrant.shortCode }}@in.teamsos.co.uk
                </p>
                <p v-if="apiGrant.parser">
                  <span class="mr-2">Email Parser:</span>
                  {{ apiGrant.parser }}
                </p>
                <p>
                  <small>
                    To protect your privacy, emails must be sent from an email
                    address that matches an active non-guest user in your
                    organisation and the sending email server must have a valid
                    SPF record for the email domain. Check with your technical
                    team if you're not sure.
                  </small>
                </p>
              </div>
            </div>
          </div>
        </a-col>
        <!-- / Email Integration -->
      </a-row>
    </div>
    <!-- / API Grant Exists -->
  </div>
</template>

<script>
const _ = require("lodash");
import { mapGetters } from "vuex";
import ApiClientThumbnail from "../../../Incidents/Show/Tabs/Escalate/ShareWith/ApiClientCard/ApiClientThumbnail.vue";
import organisationHelpers from "../../../../helpers/organisations";
import apiClientHelpers from "../../../../helpers/api-clients";
import apiGrants from "../../../../api/api-grants";
import apiClientsApi from "../../../../api/api-clients";
import Dates from "../../../../mixins/Dates";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import OrganisationDetailsPanel from './OrganisationDetailsPanel.vue';

export default {
  name: "Show",
  mixins: [Dates],
  data() {
    return {
      isLoading: false,
      apiGrant: null,
      windowWidth: window.innerWidth,
      isRevoking: false,

      isLoadingLinkTarget: false,
      linkTarget: null,

      isLoadingApiGrantSecret: false,
      apiGrantSecret: null,
    };
  },
  created() {
    this.getActiveApiGrant();
    this.getLinkTargetIfExists();
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  components: { ApiClientThumbnail, LoadingScreen, OrganisationDetailsPanel },
  computed: {
    ...mapGetters("missionControl", {
      organisations: "organisations",
    }),

    ...mapGetters("adminApiClients", {
      partners: "partners",
      apiClients: "apiClients",
    }),

    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      myPresenceId: "myPresenceId",
      membership: "membership",
    }),

    ...mapGetters("permissions", {
      canEditApiClient: "canEditApiClient"
    }),

    colSpan() {
      return this.windowWidth < 1200 ? 24 : 12;
    },

    partner() {
      return _.find(this.partners, (partner) => {
        return partner.id == this.$route.params.id;
      });
    },

    apiClient() {
      if (this.partner) {
        return _.find(this.apiClients, { id: this.partner.apiClientId });
      }
      return null;
    },

    tenantId() {
      if (!this.partner) {
        return null;
      }

      let organisation = _.find(this.organisations, (org) => {
        return org.ownerId == this.partner.id;
      });

      if (organisation) {
        return organisationHelpers.getOrganisationTenantId(organisation);
      }

      return this.partner.id;
    },

    grantedPermissions() {
      if (this.apiGrant) {
        let granted = [];
        _.each(
          apiClientHelpers.listApiClientPermissions(),
          (permissionLevel) => {
            if (
              apiClientHelpers.hasPermission(
                this.apiGrant.grantedPermissions,
                permissionLevel.value
              )
            ) {
              granted.push(permissionLevel);
            }
          }
        );
        return granted;
      }
      return [];
    },
  },
  methods: {
    goBack() {
      this.$router.push("/admin/integration/partners");
    },

    editLinkTarget() {
      this.$router.push(
        "/admin/integration/partners/" + this.partner.id + "/settings"
      );
    },

    formatGrantedAt(grantedAt) {
      return window.moment(grantedAt).format("MMMM Do YYYY, h:mm a");
    },

    openLink(link) {
      window.open(link);
    },

    getActiveApiGrant() {
      let vm = this;

      if (!vm.apiClient || !vm.selectedOrganisation) {
        return false;
      }

      vm.isLoading = true;
      apiGrants
        .getActiveApiGrant(
          this.tenantId,
          this.selectedOrganisation.id,
          this.apiClient.id
        )
        .then((r) => {
          vm.isLoading = false;
          vm.apiGrant = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          //   vm.$notification.error("Cannot load active API grant");
        });
    },

    async getLinkTargetIfExists() {
      let vm = this;
      if (this.apiClient && this.apiClient.linkTargetId) {
        vm.isLoadingLinkTarget = true;

        try {
          vm.linkTarget = await apiClientsApi
            .getApiClientLinkTarget(vm.apiClient.linkTargetId)
            .then((r) => r.data);
        } catch (e) {
          console.log(e);
        }

        if (vm.linkTarget.organisationKeyName || vm.linkTarget.secretName) {
          vm.getApiGrantSecret();
        }

        vm.isLoadingLinkTarget = false;
      }
    },

    async getApiGrantSecret() {
      let vm = this;
      vm.isLoadingApiGrantSecret = true;

      try {
        vm.apiGrantSecret = await apiGrants
          .getApiGrantSecretStatus(
            vm.tenantId,
            vm.selectedOrganisation.id,
            vm.apiClient.id
          )
          .then((r) => r.data);
      } catch (e) {
        console.log(e);
      }

      vm.isLoadingApiGrantSecret = false;
    },

    attemptRevoke() {
      let vm = this;
      this.isRevoking = true;
      apiGrants
        .revokeApiGrant(this.tenantId, this.apiGrant.id, this.myPresenceId)
        .then(() => {
          vm.$message.success("Grant revoked successfully");
          vm.$router.push("/admin/integration/partners");
        })
        .catch((e) => {
          console.log(e);
          vm.isRevoking = false;
          vm.$message.error("Error revoking grant");
        });
    },

    getGrantedByName(presenceId) {
      let membership = _.find(this.membership, { id: presenceId });
      return membership ? membership.displayName : "Unknown";
    },
  },
};
</script>

<style lang="scss">
.admin-view-partner {
  .button-margin-left {
    margin-left: 10px;
  }

  .mr-2 {
    margin-right: 6px;
  }

  .card {
    background: #fff;
    padding: 25px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
    margin-bottom: 20px;

    &.loading {
      text-align: center;
    }

    &.flex {
      display: flex;
    }

    .left {
      flex-shrink: 1;
      padding-right: 30px;
    }

    .right {
      flex-grow: 1;
    }

    .ant-avatar-lg {
      width: 60px;
      height: 60px;
      line-height: 60px;
    }

    h3 {
      margin-bottom: 15px;
    }

    .edit-link-target {
      margin-top: 20px;
    }

    &.basic-details {
      h3 {
        margin-bottom: 9px;
      }
    }

    &.granted-permissions {
      ul {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    &.support-information {
      .support-information-buttons {
        padding-top: 10px;
        .ant-btn {
          margin-right: 15px;
        }
      }
    }
  }

  .api-grant-details {
    .col-inner {
      padding-bottom: 20px;
    }

    .card,
    .col-inner {
      height: 100%;
    }
  }
}
</style>